import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async"
import { useEffect, } from 'react';
// import Profile from './Company'
// import Topics from './Topics'
// import Creator from './Creator'
// import Information from './Information'
import { ISOvserver } from 'modules/modules'
import useBackground from "hooks/useBackground"
import { SITE_TITLE, SITE_URL_BASE } from 'config/Constants';
import PrimaryHeading from "components/headings/PrimaryHeading";
import TopicsList from "./TopicsList";

type Props = {
}

export default function Index(props: Props) {

  const background = useBackground();

  /*=======================================
    背景操作用
  =======================================*/

  // アニメーション用
  useEffect(() => {
    let observer: IntersectionObserver;
    observer = ISOvserver();
    background.activate();
    return () => {
      observer?.disconnect();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{`Topics｜${SITE_TITLE}`}</title>
        <meta property="og:url" content={SITE_URL_BASE + "/topics"} />
        <meta property="og:title" content={`Topics｜${SITE_TITLE}`} />
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.3 }} // 切り替えに要する時間
      >
        <main id="p__topics_index" className="l__main_content">
          <PrimaryHeading letter="topics" modifire="descender"/>

          <TopicsList limit={8} pagenate={true} category={true} />

        </main>
      </motion.div>
    </>
  );
}