import { useEffect, useState } from 'react'
import { loaded, qs, addClass } from 'modules/modules'
import axios from 'axios'
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
import { API_BASE_URL, } from 'config/Constants';
import { Link } from 'react-router-dom';
import NavigationButton from 'components/ui/NavigationButton';

type Props = {
  // tagId: string
  // handleChangeTag: Function
  // tagTaxonomy: string
}

export default function TopicsTagsListForShow(props: Props) {

  const [tags, settags] = useState([])

  const tagTaxonomy = "article_tag"

  useEffect(() => {
    const query = "/" + tagTaxonomy;
    const uri = API_BASE_URL + query

    axios({
      method: 'get',
      url: uri,
      timeout: 10000
    })
      .then(function (res) {
        settags(res.data)
      })
      .catch(function (error) {
        addClass(qs(".c__topics_tag_list.js__load_required"), "failed");
      })
      .finally(function () {
      });
  }, [])

  function showtags(): any {
    if (tags?.length >= 1) {
      loaded(".c__topics_tag_list.js__load_required");
      return (
        tags.map((tag: any, index: number) => {
          return (
            <li key={tag.id + "category"}>
              <Link to={`/topics?tag_id=${tag.id}`} className="category_buttons" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
                # {tag.name}
              </Link>
            </li>
          )
        })
      )
    }
  }

  return (
    <>
      <ul className="c__topics_tag_list js__load_required">
        {showtags()}
      </ul>
    </>
  )
}
