import { useEffect, useState } from 'react'
import { loaded, qs, addClass } from 'modules/modules'
import axios from 'axios'
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
import { API_BASE_URL, } from 'config/Constants';

type Props = {
  tagId: string
  handleChangeTag: Function
  tagTaxonomy: string
}

const TopicsTagsList = (props: Props) => {

  const [categories, setCategories] = useState([])

  useEffect(() => {
    const query = "/" + props.tagTaxonomy;
    const uri = API_BASE_URL + query

    axios({
      method: 'get',
      url: uri,
      timeout: 10000
    })
      .then(function (res) {
        setCategories(res.data)
      })
      .catch(function (error) {
        addClass(qs(".c__topics_tag_list.js__load_required"), "failed");
      })
      .finally(function () {
      });
  }, [])

  function showCategories(): any {
    if (categories?.length >= 1) {
      loaded(".c__topics_tag_list.js__load_required");
      return (
        categories.map((category: any, index: number) => {
          return (
            <li key={category.id + "category"}><button className={"category_buttons" + (props.tagId == category.id ? " active" : "")} onClick={() => props.handleChangeTag(category.id)} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}># {category.name}</button></li>
          )
        })
      )
    }
  }

  return (
    <>
      <div className="topics_tag_scroll_box">
        <ul className="c__topics_tag_list js__load_required">
          {showCategories()}
        </ul>
      </div>
    </>
  )
}

export default TopicsTagsList