import { useEffect, useState } from 'react';
import { getParam, } from '../../../modules/modules'
import axios from 'axios';
import PrimaryHeading from 'components/headings/PrimaryHeading'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
import { API_BASE_URL } from 'config/Constants';
import { Link } from 'react-router-dom';
import useBackground from 'hooks/useBackground';

type Props = {
}

export default function TopicsList(props: Props) {

  const background = useBackground();
  
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('Asia/Tokyo');

  // 記事セット
  const [articles, setArticleList] = useState([]);

  // ページング系
  const limit = 3;
  const currentPage = 1;
  const [routeUniqueKey, setRouteUniqueKey] = useState(`${Math.random()}`);

  function composeApiUrl(): string {
    const previewModeQuery = getParam("previewMode") === "on" ? "&previewMode=on" : "";
    const query = `/topics_list?limit=${limit}&page=${currentPage}${previewModeQuery}`
    const uri = API_BASE_URL + query
    return uri
  }

  /*=======================================
    クリック制御
  =======================================*/
  // 背景非活性化
  function handleClickLink() {
    background.deactivate();
  };

  // 記事一覧を取得してセット
  useEffect(() => {
    const uri = composeApiUrl();

    axios({
      method: 'get',
      url: uri,
      timeout: 10000
    })
      .then(function (res) {
        setArticleList(res.data.contents);
      })
      .catch(function (error) {
        // 取得に失敗したエラーが表示される
      })
      .finally(function () {
      });

  }, [routeUniqueKey]);


  function showArticles(): any {
    let postTitle;
    let postUrl;
    let publishedDate;
    // let externalLink;
    if (articles?.length) {
      return (
        articles.map((article: any, index: number) => {
          postTitle = article.title; // タイトルを設定
          const url = "/topics/" + article.id;
          publishedDate = dayjs(article.date).format('YY/MM/DD');
          return (
            <Link className="topics_item" key={index} to={url}  title={postTitle} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              <p className="date">{publishedDate}</p>
              <p className="title">{postTitle}</p>
            </Link>
          )
        })
      )
    } else {
      return (
        <p className="c__article_notfound c__common_text">
          データの取得に失敗しました。<br />
          時間をおいて再度お試しください。
        </p>
      )
    }
  }

  return (
    <>

      <div className="topics_list_wrapper">
        <div className="heading_wrapper">
          <PrimaryHeading letter="topics" modifire='descender' />
          <Link to="/topics" onClick={handleClickLink} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
            <p className="text">in more detail　{">"}</p>
          </Link>
        </div>

        <div className="topics_list">
          {showArticles()}
        </div>

      </div>

    </>
  );
}