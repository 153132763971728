import { useLocation } from 'react-router-dom';

const useCurrentPage = (): string => {

  const location = useLocation();
  const path = location.pathname;
  const pathAndHash = location.pathname + location.hash

  if (pathAndHash.includes("about")) {
    return "about"
  } else if (pathAndHash.includes("virtual_estate")) {
    return "virtual_estate"
  } else if (path.includes("works")) {
    return "works"
  } else if (path.includes("topics")) {
    return "topics"
  } else if (pathAndHash.includes("contact")) {
    return "contact"
  } else if (pathAndHash.includes("terms")) {
    return "terms"
  } else {
    return "home"
  }
}
export default useCurrentPage;