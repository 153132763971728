import { useEffect, useState } from 'react'
import { loaded, qs, addClass } from 'modules/modules'
import axios from 'axios'
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
import { API_BASE_URL, } from 'config/Constants';
import { Link } from 'react-router-dom';

type Props = {
  // catId: string
  // handleChangeCategory: Function
  // categoryTaxonomy: string
}

const TopicsCategoriesListForShow = (props: Props) => {

  const [categories, setCategories] = useState([])
  const categoryTaxonomy = "article_category"

  useEffect(() => {
    const query = "/" + categoryTaxonomy
    const uri = API_BASE_URL + query

    axios({
      method: 'get',
      url: uri,
      timeout: 10000
    })
      .then(function (res) {
        setCategories(res.data)
      })
      .catch(function (error) {
        addClass(qs(".c__topics_category_list.js__load_required"), "failed");
      })
      .finally(function () {
      });
  }, [])



  function showCategories(): any {
    let allButton = [
      <li key="all">
        <Link to="/topics" className="category_buttons" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
          All
        </Link>
      </li>
    ]
    if (categories?.length >= 1) {
      loaded(".c__topics_category_list.js__load_required");
      return (
        allButton.concat(
          categories.map((category: any, index: number) => {
            return (
              <li key={category.id + "category"}>
                <Link to={`/topics?article_category=${category.id}`} className="category_buttons" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
                  {category.name}
                </Link>
              </li>
            )
          })
        )
      )
    }
  }

  return (
    <>

      <ul className="c__topics_category_list js__load_required">
        {showCategories()}
      </ul>
    </>
  )
}

export default TopicsCategoriesListForShow