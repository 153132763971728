import { useEffect, useState } from 'react';
import { getParam, } from '../../../modules/modules'
import axios from 'axios';
import PrimaryHeading from 'components/headings/PrimaryHeading'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
import { API_BASE_URL, topicsCategoryIds } from 'config/Constants';
import NavigationButton from 'components/ui/NavigationButton';
import { Link } from 'react-router-dom';
import useBackground from 'hooks/useBackground';

type Props = {
}

export default function ServicesList(props: Props) {
  
  const background = useBackground();

  /*=======================================
    クリック制御
  =======================================*/
  // 背景非活性化
  function handleClickLink() {
    background.deactivate();
  };

  return (
    <>

      <div className="service_list_wrapper">
        <PrimaryHeading letter="service" />
        <div className="service_list">

          {/* todo 要リンク先設定 */}
          <Link className="service_item" to={`/topics?category_id=${topicsCategoryIds.production}`} onClick={handleClickLink} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
            <div className="text_wrapper">
              <p className="title">制作事業</p>
              <p className="description">映像 ／ デザイン ／ 3D ／ イラスト</p>
            </div>
            <NavigationButton variant='bl_thin' />
          </Link>

          {/* todo 要リンク先設定 */}
          <Link className="service_item" to={`/topics?category_id=${topicsCategoryIds.supervision}`} onClick={handleClickLink} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
            <div className="text_wrapper">
              <p className="title">監修事業</p>
              <p className="description">企画 ／ ディレクション</p>
            </div>
            <NavigationButton variant='bl_thin' />
          </Link>

          {/* todo 要リンク先設定 */}
          <Link className="service_item" to={`/topics?category_id=${topicsCategoryIds.setup}`} onClick={handleClickLink} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
            <div className="text_wrapper">
              <p className="title">環境構築</p>
              <p className="description">技術サポート ／ アドバイザー</p>
            </div>
            <NavigationButton variant='bl_thin' />
          </Link>
        </div>

      </div>

    </>
  );
}