import { useEffect, useState, } from 'react';
import { Link, } from 'react-router-dom';
import { loaded, getParam, addClass, join, qs, removeClass, ISOvserve } from '../../../modules/modules'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import axios from 'axios';
import noimage from 'assets/img/common/noimage.jpg'
import TopicsCategoriesList from './TopicsCategoriesList'
import TopicsTagsList from './TopicsTagsList'
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
import { API_BASE_URL } from 'config/Constants';
import TopicsPagenation from './TopicsPagenation';


type Props = {
  limit: number
  pagenate: boolean
  category: boolean
}

export default function TopicsList(props: Props) {

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('Asia/Tokyo');

  // 記事セット
  const [articles, setArticleList] = useState([]);

  // ページング系
  const limit = props.limit;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [catId, setCatId] = useState(initCategory()); // カテゴリID
  const [tagId, setTagId] = useState(initTag()); // タグID

  const [routeUniqueKey, setRouteUniqueKey] = useState(`${Math.random()}`);

  // 初回画面表示時に選択されているカテゴリIDを取得
  function initCategory(): string {
    const tagParams = getParam("tag_id");
    if (tagParams) {
      return "";
    }
    const categoryParams = getParam("category_id");
    return categoryParams || "all";
  }

  function initTag(): string {
    const params = getParam("tag_id");
    return params || "";
  }

  // カテゴリ変更時は記事をリセット
  function handleChangeCategory(newCatId: string) {
    if (newCatId === catId) return

    const list = qs(".c__topics_list");
    addClass(list, "disabled");
    // 消えるアニメーションが完了するまで待つ
    setTimeout(() => {
      setCatId(newCatId)
      setTagId("")
      setCurrentPage(1)
      setArticleList([])
    }, 100);
  }

  // タグ変更時は記事をリセット
  function handleChangeTag(newTagId: string) {
    if (newTagId === tagId) return

    const list = qs(".c__topics_list");
    addClass(list, "disabled");
    // 消えるアニメーションが完了するまで待つ
    setTimeout(() => {
      setCatId("")
      setTagId(newTagId)
      setCurrentPage(1)
      setArticleList([])
    }, 100);
  }

  function composeApiUrl(): string {
    const previewModeQuery = getParam("previewMode") === "on" ? "&previewMode=on" : "";
    const categoryParam = catId === "all" ? "" : `category=${catId}&`
    const tagParam = tagId === "" ? "" : `tag=${tagId}&`
    const query = `/topics_list?${categoryParam}${tagParam}limit=${limit}&page=${currentPage}${previewModeQuery}`
    const uri = API_BASE_URL + query

    return uri
  }


  // 記事一覧を取得してセット
  useEffect(() => {

    const loader = qs(".c__loader");
    addClass(loader, "active");

    const uri = composeApiUrl();

    axios({
      method: 'get',
      url: uri,
      timeout: 10000
    })
      .then(function (res) {
        let tempArticles = res.data.contents;
        // tempArticles = tempArticles.concat(res.data.contents);
        setArticleList(tempArticles);
        setTotalPages(parseInt(res.data.total_pages)); //総ページ数
        if (tempArticles.length == 0) {
          removeClass(qs(".c__article_notfound"), "disabled");
        }
      })
      .catch(function (error) {
        // 取得できませんでしたみたいなやつが表示される
        removeClass(qs(".c__article_notfound"), "disabled");
      })
      .finally(function () {
        loaded(".articles_wrapper.js__load_required");
        removeClass(loader, "active");
      });

  }, [currentPage, catId, tagId, routeUniqueKey]);

  // サムネURLの設定
  function getImageSrc(img_src: any) {
    return img_src ? img_src : noimage
    // if (img_src) {
    //   return img_src
    // } else {
    //   return noimage
    // }
  }

  function showHashTags(params: string[]) {
    return params.map((param: string, i: number) => {
      return (
        <p key={i} className="hashtag">#{param}</p>
      )
    })
  }

  function showArticles() {
    // let postTitle;
    // let categories: string[] = [];
    // let postUrl;
    let previewQuery = getParam("previewMode") === "on" ? "?previewMode=on" : "";

    if (articles?.length >= 1) {
      return (
        <div className="c__topics_list ">
          {
            articles.map((article: any, index: number) => {
              // タイトルの取得
              const title = String(article.title);
              // URLの取得
              const url = "/topics/" + article.id + previewQuery;
              // カテゴリの取得
              const categories = article.cat_names || [];
              // タグの取得
              const tags = article.tag_names || [];

              const hashTags = categories.concat(tags);
              // 日付の取得
              const date = dayjs(article.date).format('YY/MM/DD');
              // 画像の取得
              const imgSrc = getImageSrc(article.img_src)
              return (

                <Link
                  key={article.id}
                  to={url}
                  className={`topics_item anchor delay${index % 12}`}
                  title={title}
                  onMouseOver={activateHoverCursor}
                  onMouseOut={deactivateHoverCursor}
                // state={{ totalNum: totalPages * limit }}
                >
                  <div className="topics_item_info">
                    <div className="info_l">
                      <div className="thumbnail_wrapper">
                        <img className="thumbnail" src={imgSrc} alt={title} />
                      </div>
                    </div>
                    <div className="info_r">
                      <div className="info_r_sub_info">
                        <p className="date">{date}</p>
                        <div className="hashtags">{showHashTags(hashTags)}</div>
                      </div>
                      <div className="info_r_title_pc u__sp_none">
                        <p className="topics_title">{title}</p>
                      </div>
                    </div>
                  </div>
                  <div className="topics_item_title_sp u__sp_only">
                    <p className="topics_title">{title}</p>
                  </div>
                  <div className="topics_item_decorations">
                    <span className="dot"></span>
                    <span className="line"></span>
                    <span className="dot"></span>
                  </div>
                </Link>
              )
            })}
        </div>
      )
    } else {
      return (
        <p className="c__article_notfound c__common_text disabled">
          記事が存在しませんでした。<br />
          他のカテゴリやタグでお試しください。
        </p>
      )
    }
  }


  return (
    <>
      <TopicsCategoriesList categoryTaxonomy="article_category" catId={catId} handleChangeCategory={handleChangeCategory} />

      <div className="article_section_wrapper">

        <div className="article_section_l">
          <div className="articles_wrapper js__load_required" key={routeUniqueKey}>
            {showArticles()}
          </div>
          {props.pagenate && totalPages >= 2 &&
            <TopicsPagenation
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          }
          <div className="c__loader active">
            <span className="loader_dot first"></span>
            <span className="loader_dot second"></span>
            <span className="loader_dot third"></span>
          </div>
        </div>
        <div className="article_section_r">
          <TopicsTagsList tagTaxonomy="article_tag" tagId={tagId} handleChangeTag={handleChangeTag} />
        </div>
      </div>

    </>
  );
}