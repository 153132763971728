import arrow_wh from 'assets/img/icon/arrow_wh.svg'
import arrow_bk_bold from 'assets/img/icon/arrow_bk_bold.svg'
import arrow_wh_bold from 'assets/img/icon/arrow_wh_bold.svg'
import arrow_bl_thin from 'assets/img/icon/arrow_bl_thin.svg'
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'

type Props = {
  variant?: string
  [key: string]: any;
}

export default function NavigationButton({ ...props }: Props,) {

  function variant() {
    return props.variant ? " " + props.variant : ""
  }

  function arrowSrc() {
    if (props.variant?.includes("bold")) {
      return arrow_wh_bold
    } else if (props.variant?.includes("gray")) {
      return arrow_bk_bold
    } else if (props.variant?.includes("bl_thin")) {
      return arrow_bl_thin
    } else {
      return arrow_wh
    }
  }

  function generate() {
    return (
      <button
        className={"c__navigation_button" + variant()}
        onMouseOver={activateHoverCursor}
        onMouseOut={deactivateHoverCursor}
        {...props}
      >
        <img src={arrowSrc()} />
      </button>
    )
  }

  return (
    generate()
  );
}