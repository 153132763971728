import PrimaryHeading from 'components/headings/PrimaryHeading'
import profile_image from "assets/img/about/nin.png";
import NavigationButton from 'components/ui/NavigationButton'
import { qsAll, scrollToSelector, loaded, } from "modules/modules"
import { addStalkerHoverEvent, removeStalkerHoverEvent, activateHoverCursor, deactivateHoverCursor } from "modules/mouseStalker"

import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from 'config/Constants';

type Props = {
}

export default function Profile(props: Props) {

  const [companyInfo, setCompanyInfo] = useState("");
  const [companyIcon, setCompanyIcon] = useState("");

  function composeApiUrl(): string {
    const query = `/about_profile`
    const uri = API_BASE_URL + query
    return uri
  }

  // 記事詳細を取得
  useEffect(() => {

    axios({
      method: 'get',
      url: composeApiUrl(),
      timeout: 10000
    })
      .then(function (res) {
        // console.log(res);

        // 記事情報のセット
        setCompanyInfo(res.data.content);
        setCompanyIcon(res.data.icon);
      })
      .catch(function (e) {
        setCompanyInfo('<p className="c__article_notfound c__common_text">データの取得に失敗しました。<br />時間をおいて再度お試しください。</p>');
        setCompanyIcon(profile_image);
      })
      .finally(function () {
        loaded(".profile_image.js__load_required");
      });
  }, []);


  /*=======================================
    記事内リンクに対しての設定
  =======================================*/
  // aタグに対してホバーの反応と外部リンクで開く設定を追加
  useEffect(() => {
    const selector: string = ".c__article_body a"
    setTimeout(() => {
      addStalkerHoverEvent(selector);
      const anchors = qsAll(selector);
      for (const anchor of anchors) {
        anchor.setAttribute("target", "_blank");
        anchor.setAttribute("rel", "noopener noreferrer");
      }
    }, 500);
    return () => {
      removeStalkerHoverEvent(selector);
    }
  }, []);

  return (
    <>
      <div className="about_profile l__content_width c__js_fade">
        <PrimaryHeading letter="company" modifire='descender' />

        <div className="about_profile_inner">
          <div className="profile_l">
            <img className="profile_image js__load_required" src={companyIcon} alt="nin" width={140} height={140} />
            <div className="scroll_to_creator" onClick={() => scrollToSelector("#js__creator_section")} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              <span>Creator</span>
              <NavigationButton variant="down bold" />
            </div>
          </div>

          <div className="c__article_body profile_r" dangerouslySetInnerHTML={{ __html: companyInfo }} />

        </div>



      </div>
    </>
  );
}
