import { useEffect, } from "react";
import { addClass, qsAll, addClassAll, removeClassAll, qs, ISOvserve, removeClass, getCookieValue } from '../../../modules/modules'
import logo_wh from 'assets/img/common/logo_bk.svg'
import grad_blue from 'assets/img/home/grad_blue3.jpg'
import grad_red from 'assets/img/home/grad_red.jpg'
import Blob from './Blob'
import useFirstView from 'hooks/useFirstView'
import { useLocation } from 'react-router-dom';

type Props = {
}

export default function FirstView(props: Props) {

  const location = useLocation();
  const fv = useFirstView();


  setTimeout(() => {
    // cookieをセット
    // 初回のレンダリングが終わるまでの時間を稼いでからセットする
    document.cookie = "VISITED=TRUE; expires=3"; // 3日間有効
  }, 5000);


  /*=======================================
    アニメーションの制御
  =======================================*/
  useEffect(() => {

    // 初回の場合
    if (fv.isFirstVisit && !location.hash.includes("works")) {

      // スクロールを無効にする
      preventScroll();

      // 白のblobをアクティブにし、小さい状態から拡大させる
      setTimeout(() => {
        addClass(qs('.blob_wrapper.white'), 'active');
      }, 500);

      // 画面中央にロゴやコピーを表示
      setTimeout(() => {
        addClassAll(qsAll('.fv_logo, .fv_copy, .scrolldown'), 'active');
      }, 2500);

      // 青と緑のblobと、背景の青緑グラデーションをアクティブにする
      setTimeout(() => {
        addClassAll(qsAll('.blob_wrapper.corporate_blue, .blob_wrapper.blue, .fv_bg_grad.blue'), 'active');
        removeClassAll(qsAll("#header .header_r, #header .header_l, #header_toggle"), 'disabled'); // 始めから付いているクラスを削除
      }, 3000);

      // スクロールを許可する
      setTimeout(() => {
        handleFvScroll();
        arrowScroll();
      }, 4000);
    }

    return () => {
      arrowScroll();
      removeHandleFvScroll();
    }
  }, [fv.isFirstVisit]);

  // スクロールされたらFVを非表示にするアニメーション
  function playFvScrollAnimation(e: any) {
    e.preventDefault();

    const offset = qs('#js__recommendation').offsetTop;

    document.documentElement.scrollTop = offset;
    addClass(qs('#first_view'), 'scrolled');
    setTimeout(() => {
      removeHandleFvScroll();
      arrowScroll();
      fv.scrolled(); // estateButtonの制御に使用中
    }, 1000);
    setTimeout(() => {
      fv.visited();
    }, 1500);
  }

  // スクロールされたらFVを非表示にするアニメーションを再生する
  function handleFvScroll() {
    document.addEventListener("wheel", playFvScrollAnimation, { passive: false });
    document.addEventListener("touchmove", playFvScrollAnimation, { passive: false });
  }

  // handleFvScrollを解除
  function removeHandleFvScroll() {
    document.removeEventListener("wheel", playFvScrollAnimation);
    document.removeEventListener("touchmove", playFvScrollAnimation);
  }
  // スクロール禁止
  function preventScroll() {
    document.addEventListener("wheel", preventDef, { passive: false });
    document.addEventListener("touchmove", preventDef, { passive: false });
  }
  // スクロール禁止解除
  function arrowScroll() {
    document.removeEventListener("wheel", preventDef);
    document.removeEventListener("touchmove", preventDef);
  }
  // preventDefault
  function preventDef(e: any) {
    e.preventDefault();
  }

  /*=======================================
    ヘッダーがFVの上にあるときはホバーで文字の色が変わらないようにし、ドットを白くする
  =======================================*/
  useEffect(() => {
    let observer: IntersectionObserver = null;
    const header = qs("#header .header_r");
    const targetSelector = "#first_view"; //記事の要素の一番最後
    const options = {
      rootMargin: "-20px 0px 0px 0px",
    };
    observer = ISOvserve(
      targetSelector,
      options,
      () => { addClass(header, "on_fv"); },
      () => { removeClass(header, "on_fv"); },
    )
    return () => {
      observer?.disconnect();
      removeClass(header, "on_fv")
    };
  }, [])

  return (
    <>
      {/* FVスクロール時、JSでスクロール位置をガっと動かしてることを隠すためのオーバーレイ */}
      <div id="fv_overlay_bg" className={fv.isScrolled ? "scrolled" : ""}></div>

      <div id="first_view" className={fv.isFirstVisit ? "first_visit" : "revisit"}>

        <div className="animation_elements">
          <img className="fv_bg_grad red" src={grad_red} alt="" />
          <img className="fv_bg_grad blue" src={grad_blue} alt="" />
          <span className="fv_bg_blue"></span>
          <div className="fv_circle">
            <div className="fv_circle_inner">
              <img className="fv_logo" src={logo_wh} alt="合同会社nin" />
              <p className="fv_copy">自分らしく、あなたらしく</p>
              <div className="scrolldown"></div>
              <span className="white_circle"></span>
              <Blob modifire="white" />
              <Blob modifire="corporate_blue" />
              <Blob modifire="blue" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
