import { useEffect } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { qs, qsAll, join, loaded, removeClass, addClass } from 'modules/modules'
import { addStalkerHoverEvent, removeStalkerHoverEvent } from "modules/mouseStalker"
import useBackground from "hooks/useBackground"


type Props = {
  article: Article
}
type Article = {
  title: string
  date: string
  categories: string[]
  tags: string[]
  content: string
  excerpt: string
}

export default function TopicsArticle(props: Props) {

  const article = props.article;

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('Asia/Tokyo');

  const background = useBackground();

  useEffect(() => {
    background.activate()
    setTimeout(() => {
      loaded(".article_wrapper.js__load_required");
    }, 300);
  }, [article]);

  /*=======================================
    記事内リンクに対しての設定
  =======================================*/
  // aタグに対してホバーの反応と外部リンクで開く設定を追加
  useEffect(() => {
    const selector: string = ".c__article_body a"
    setTimeout(() => {
      addStalkerHoverEvent(selector);
      const anchors = qsAll(selector);
      for (const anchor of anchors) {
        anchor.setAttribute("target", "_blank");
        anchor.setAttribute("rel", "noopener noreferrer");
      }
    }, 500);
    return () => {
      removeStalkerHoverEvent(selector);
    }
  }, []);

  // iframeと重なったらマウスストーカーを非表示にする
  useEffect(() => {
    let stalker: HTMLElement | null = null;
    let iframes: Array<HTMLElement> = [];
    setTimeout(() => {
      stalker = qs("#mouse_stalker");
      iframes = qsAll("iframe");
      for (const elm of iframes) {
        elm.addEventListener('mouseover', function () {
          addClass(stalker, "disabled");
        });
        elm.addEventListener('mouseout', function () {
          removeClass(stalker, "disabled");
        });
      }
    }, 1000);
    return () => {
      for (const elm of iframes) {
        elm.removeEventListener('mouseover', function () {
          addClass(stalker, "disabled");
        });
        elm.removeEventListener('mouseout', function () {
          removeClass(stalker, "disabled");
        });
      }
    }
  }, []);

  function showHashTags(cateogires: string[], tags: string[]) {
    const concat: string[] = cateogires.concat(tags)
    return concat.map((param: string, i: number) => {
      return (
        <p key={i} className="hashtag">#{param}</p>
      )
    })
  }

  return (
    <>
      <article className="article_wrapper js__load_required">

        <div className="article_head_info">
          <p className="article_head_info_date">{article.date}</p>
          <div className="article_head_info_hashtags">{showHashTags(article.categories, article.tags)}</div>
        </div>

        <h1 className="article_title">{article.title}</h1>

        <div className="topics_item_decorations">
          <span className="dot"></span>
          <span className="line"></span>
          <span className="dot"></span>
        </div>

        <div className="c__article_body main_content" dangerouslySetInnerHTML={{ __html: article.content }} />


      </article>
    </>
  );
}