import axios from 'axios'
import { useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { motion } from "framer-motion"
import { Helmet } from "react-helmet-async"
import noimage from 'assets/img/common/noimage.jpg'
import { qs, getParam, ISOvserver, removeClass, unEscapeHtml } from 'modules/modules'
import PrimaryArticle from 'components/layouts/articles/PrimaryArticle'
import useBackground from "hooks/useBackground"
import { API_BASE_URL } from 'config/Constants';
import TopicsArticle from './TopicsArticle'
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
import TopicsCategoriesListForShow from './TopicsCategoriesListForShow'
import TopicsTagsListForShow from './TopicsTagsListForShow'
import NavigationButton from 'components/ui/NavigationButton'

type Props = {
}
type Article = {
  title: string
  date: string
  categories: string[]
  tags: string[]
  content: string
  excerpt: string
  related: any
}
const initialArticle: Article = {
  title: "",
  date: "",
  categories: [],
  tags: [],
  content: "",
  excerpt: "",
  related: null,
};

const Show = (props: Props) => {

  const { id } = useParams(); //ルーティングで使っているパラメータ
  const [article, setArticle] = useState<Article>(initialArticle);

  const navigate = useNavigate();


  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('Asia/Tokyo');

  const background = useBackground();

  // 記事詳細を取得
  useEffect(() => {
    // loading();
    background.deactivate()

    const previewQuery = getParam("previewMode") === "on" ? "&previewMode=on" : "";
    const query = `/topics_detail/?id=${id}${previewQuery}`;
    const uri = API_BASE_URL + query

    axios({
      method: 'get',
      url: uri,
      timeout: 10000
    })
      .then(function (res) {

        if (res.data === null) {
          navigate('/notfound')
        }

        const artice: Article = {
          title: String(res.data.title),
          date: dayjs(res.data.date).format('YY/MM/DD'),
          categories: res.data.cat_names,
          tags: res.data.tag_names,
          content: res.data.content,
          excerpt: res.data.caption + unEscapeHtml(res.data.excerpt),
          related: res.data.related
        };

        // 記事情報のセット
        setArticle(artice);

        if (res.data.related.length == 0) {
          removeClass(qs(".c__article_notfound"), "disabled");
        }
      })
      .catch(function (e) {
        navigate('/notfound')
      })
      .finally(function () {
      });
  }, []);

  // アニメーション用
  useEffect(() => {
    let observer: IntersectionObserver;
    observer = ISOvserver();
    return () => {
      observer?.disconnect();
    };
  }, []);

  function img_src(article: any) {
    if (article.img_src) {
      return article.img_src
    } else {
      return noimage
    }
  }

  function showHashTags(params: string[]) {
    return params.map((param: string, i: number) => {
      return (
        <p key={i} className="hashtag">#{param}</p>
      )
    })
  }

  function showRelatedArticles(articles: any) {
    // let postTitle;
    // let categories: string[] = [];
    // let postUrl;
    let previewQuery = getParam("previewMode") === "on" ? "?previewMode=on" : "";

    if (articles?.length >= 1) {
      return (
        <div className="c__topics_list ">
          {
            articles.map((article: any, index: number) => {
              // 表示中の記事は除外
              if (article.id == id) {
                return;
              }

              // タイトルの取得
              const title = String(article.title);
              // URLの取得
              const url = "/topics/" + article.id + previewQuery;
              // カテゴリの取得
              const categories = article.cat_names || [];
              // タグの取得
              const tags = article.tag_names || [];

              const hashTags = categories.concat(tags);
              // 日付の取得
              const date = dayjs(article.date).format('YY/MM/DD');
              return (

                <Link
                  key={article.id}
                  to={url}
                  className={`topics_item anchor delay${index % 12}`}
                  title={title}
                  onMouseOver={activateHoverCursor}
                  onMouseOut={deactivateHoverCursor}
                // state={{ totalNum: totalPages * limit }}
                >
                  <div className="topics_item_info">
                    <div className="info_r">
                      <div className="info_r_sub_info">
                        <p className="date">{date}</p>
                        <div className="hashtags">{showHashTags(hashTags)}</div>
                      </div>
                      <div className="info_r_title_pc u__sp_none">
                        <p className="topics_title">{title}</p>
                      </div>
                    </div>
                  </div>
                  <div className="topics_item_title_sp u__sp_only">
                    <p className="topics_title">{title}</p>
                  </div>
                  <div className="topics_item_decorations">
                    <span className="dot"></span>
                    <span className="line"></span>
                    <span className="dot"></span>
                  </div>
                </Link>
              )
            })}
        </div>
      )
    }
    // else {
    //   return (
    //     <p className="c__article_notfound c__common_text disabled">
    //       データの取得に失敗しました。<br />
    //       時間をおいて再度お試しください。
    //     </p>
    //   )
    // }
  }
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{article.title} Topics - 合同会社NIN</title>
        <meta property="og:title" content={article.title + " Topics - 合同会社nin"} />
        <meta property="og:description" content={article.excerpt} />
        <meta name="description" content={article.excerpt} />
        <meta property="og:url" content={"https://ninworks.com/topics/" + id} />
      </Helmet>

      <motion.div
        initial={{ opacity: 0 }} // このページに遷移した直後
        animate={{ opacity: 1 }} // 最終的な状態
        exit={{ opacity: 0 }} // 離れる際にどうなるか
        transition={{ duration: 0.3 }} // 切り替えに要する時間
      >
        <main id="p__topics_show" className="">
          <TopicsArticle article={article} />

          <div className={`bottom_section ${article.related?.length ? "" : "no_related"}`}>
            {article.related?.length >= 1 &&
              <div className="other_topics_wrapper">
                {showRelatedArticles(article.related)}
                <Link className="other_topics_view_all u__sp_none" to="/topics" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
                  <p className="text">View all</p>
                  <NavigationButton variant='bl_thin' />
                </Link>
              </div>
            }
            <div className="category_tag_wrapper">
              <TopicsCategoriesListForShow />
              <TopicsTagsListForShow />
            </div>
          </div>

          <Link className="view_all_nav sp_only" to="/topics">
            <p className="text">View all</p>
            <NavigationButton variant='bl_thin' />
          </Link>

        </main>
      </motion.div>
    </>
  );
}
export default Show;