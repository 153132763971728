import { useEffect, useState, useMemo } from 'react';
import { hasClass, removeClass, getParam, addClass, qsAll, addClassAll, removeClassAll, qs, nl2br } from '../../../modules/modules'
import axios from 'axios';
import PrimaryHeading from 'components/headings/PrimaryHeading'
import NavigationButton from 'components/ui/NavigationButton'
import noimage from 'assets/img/about/nin.png'
import youtube_cl from 'assets/img/icon/youtube_cl.svg'
import x_logo_bk from 'assets/img/icon/x_logo_bk.svg'
import { activateHoverCursor, deactivateHoverCursor } from 'modules/mouseStalker'
import { API_BASE_URL } from 'config/Constants';

// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";

type Props = {
}

export default function Creator(props: Props) {

  //========================================================================================
  //  記事の取得
  // =======================================================================================

  // 記事セット
  const [articles, setArticleList] = useState([]);

  const [routeUniqueKey, setRouteUniqueKey] = useState(`${Math.random()}`);

  function composeApiUrl(): string {
    const previewModeQuery = getParam("previewMode") === "on" ? "&previewMode=on" : "";
    const query = `/creators?${previewModeQuery}`
    const uri = API_BASE_URL + query
    return uri
  }

  // サムネURLの設定
  function img_src(article: any) {
    if (article.icon) {
      return article.icon
    } else {
      return noimage
    }
  }

  // 記事一覧を取得してセット
  useEffect(() => {
    const uri = composeApiUrl();
    axios({
      method: 'get',
      url: uri,
      timeout: 10000
    })
      .then(function (res) {
        setArticleList(res.data.contents);
      })
      .catch(function (error) {
        // 取得に失敗したエラーが表示される
      })
      .finally(function () {
      });
  }, [routeUniqueKey]);


  //========================================================================================
  //  クリエイター一覧
  // =======================================================================================
  const showCreatorsList = useMemo(() => {
    let name;
    let position;
    if (articles?.length) {
      return (
        articles.map((article: any, index: number) => {
          name = article.title; // タイトルを設定
          position = article.position; // 肩書
          return (
            <button className={"creator_button" + (index === 0 ? " active" : "")} key={index + "creator_name"} onClick={(e) => handleCreatorClick(e)} data-creator-index={index} onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
              <p className="position">{position}</p>
              <p className="name">{name}</p>
            </button>
          )
        })
      )
    }
  }, [articles])

  // 名前がクリックされたら対応するスライドに移動する
  function handleCreatorClick(e: any) {
    removeClassAll(qsAll(".creator_button"), "active");
    addClass(e.target, "active");
    const swiper = qs('.about_creator_swiper').swiper;
    const index = e.target.getAttribute('data-creator-index');
    swiper.slideToLoop(index, 800);
  }

  //========================================================================================
  //  プロフィールの省略
  // =======================================================================================

  // 5行以上だった場合は省略ボタンを表示する
  useEffect(() => {
    switchElipsis()
    let timeoutId: NodeJS.Timeout;
    window.addEventListener("resize", () => { timeoutId = handleResize(timeoutId) });
    return () => {
      window.removeEventListener("resize", () => { timeoutId = handleResize(timeoutId) });
    }
  }, [articles]);

  function handleResize(timeoutId: NodeJS.Timeout) {
    // リサイズを停止して500ms後に終了とする
    clearTimeout(timeoutId);
    return timeoutId = setTimeout(function () {
      switchElipsis()
    }, 500);
  }

  function switchElipsis(): void {

    // ブレークポイントの設定
    const breakpoints = {
      sp: {
        width: 712,
        totalLineLimit: 18,
        profileLineLimit: 8,
        careerLineLimit: 10,
      },
      tab: {
        width: 920,
        totalLineLimit: 20,
        profileLineLimit: 5,
        careerLineLimit: 15,
      },
      pc: {
        width: 99999,
        totalLineLimit: 20,
        profileLineLimit: 5,
        careerLineLimit: 15,
      },
    }

    // 現在のブレークポイントを取得
    let currentBreakPoint: any;
    const windowWidth = window.innerWidth;
    if (windowWidth <= breakpoints.sp.width) {
      currentBreakPoint = breakpoints.sp
    } else if (windowWidth <= breakpoints.tab.width) {
      currentBreakPoint = breakpoints.tab
    } else {
      currentBreakPoint = breakpoints.pc
    }


    // スライダーの各要素を取得
    const slides = qsAll(".creator_item");

    // 各スライドごとに処理を実行 
    for (const slide of slides) {

      // プロフィールと経歴の要素を取得
      const profile = slide.querySelector(".profile");
      const career = slide.querySelector(".career");

      // 省略の状態を初期化する

      // 展開ボタンを取得
      const profileOmitButton = slide.querySelector(".profile_omission");
      const careerOmitButton = slide.querySelector(".career_omission");

      // 展開ボタンの向きをリセットする
      removeClass(profileOmitButton, "spread");
      removeClass(careerOmitButton, "spread");

      // 展開ボタンを非表示にする
      removeClass(profileOmitButton, "active");
      removeClass(careerOmitButton, "active");

      // 文章の省略を解除する
      removeClass(profile, "js__will_elipsis");
      removeClass(career, "js__will_elipsis");

      // 文章の省略を解除する
      removeClass(profile, "elipsis_active");
      removeClass(career, "elipsis_active");

      //  行数を取得する 
      const profileLineNum = calcLineNum(profile);
      const careerLineNum = calcLineNum(career);

      if ((profileLineNum + careerLineNum) > currentBreakPoint.totalLineLimit) {
      // if (profileLineNum > currentBreakPoint.totalLineLimit) {

        // 既定の行数を超えていたら省略用のクラスを付ける
        if (profileLineNum > currentBreakPoint.profileLineLimit) {
          addClass(profileOmitButton, "active");
          addClass(profile, "js__will_elipsis");
        }

        // 既定の行数を超えていたら省略用のクラスを付ける
        if (careerLineNum >= currentBreakPoint.careerLineLimit) {
          addClass(careerOmitButton, "active");
          addClass(career, "js__will_elipsis");
        }
      }
    }

    // 省略対象であれば省略する
    addClassAll(qsAll(".js__will_elipsis"), "elipsis_active");

  }

  // 行数を計算する
  function calcLineNum(elm: HTMLElement | null): number {
    if (!elm) return 0;
    const lineHeight = getComputedStyle(elm).lineHeight;
    const height = getComputedStyle(elm).height;
    const lineNum = Math.round(parseFloat(height) / parseFloat(lineHeight));
    return lineNum;
  }

  // クリックされたら広げたり閉じたりする
  // スライダーのloopをtrueにしているので、複製されたスライドでも適用できるようqsAllで取得
  function handleClickNavButton(e: React.MouseEvent<HTMLInputElement>, className: string) {
    const target = e.target as HTMLElement;
    const elms = qsAll(`.${className}`);
    for (const elm of elms) {
      if (hasClass(elm, "elipsis_active")) {
        removeClass(elm, "elipsis_active");
        addClass(target, "spread");
      } else {
        addClass(elm, "elipsis_active");
        removeClass(target, "spread");
      }
    }
  }


  //========================================================================================
  //  クリエイタースライダー
  // =======================================================================================
  const showCreatorsSlider = useMemo(() => {
    let name;
    let name_en;
    let position;
    let icon;
    let youtubeUrl;
    let twitterUrl;
    let profile: string;;
    let career;
    if (articles?.length) {
      return (
        articles.map((article: any, index: number) => {
          name = article.title;           // 名前
          name_en = article.name_en;      // 名前(英語)
          position = article.position;    // 肩書
          icon = article.icon;            // アイコン
          youtubeUrl = article.youtube_url;    // サブアイコン
          twitterUrl = article.twitter_url;    // サブアイコン
          profile = article.profile;      // プロフィール
          career = article.career;        // 経歴
          return (
            <SwiperSlide key={index + "creator_item"} className={`creator_item`}>
              <div className="sns_info">
                {twitterUrl &&
                  <a href={twitterUrl} target="_blank" rel="noopener noreferrer" className="tw" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
                    <img src={x_logo_bk} className="" alt="" />
                  </a>}
                {youtubeUrl &&
                  <a href={youtubeUrl} target="_blank" rel="noopener noreferrer" className="yt" onMouseOver={activateHoverCursor} onMouseOut={deactivateHoverCursor}>
                    <img src={youtube_cl} alt="" />
                  </a>
                }
              </div>
              <div className="head_info">
                <img src={img_src(article)} className="info_l" alt="" />
                <div className="info_r">
                  <p className="name_en">{name_en}</p>
                  <p className="name">{name}</p>
                  <p className="position">{position}</p>
                </div>
              </div>
              <p className={"profile" + (twitterUrl || youtubeUrl ? "" : " no_sns_icon") + ` profile${index}`} >{nl2br(profile)}</p>
              <NavigationButton variant={"gray profile_omission"} onClick={(e: React.MouseEvent<HTMLInputElement>) => handleClickNavButton(e, `profile${index}`)} />
              {/* <p className={"career" + ` career${index}`}>{nl2br(career)}</p> */}
              <p className={"career" + ` career${index}`}>{career}</p>
              <NavigationButton variant={"gray career_omission"} onClick={(e: React.MouseEvent<HTMLInputElement>) => handleClickNavButton(e, `career${index}`)} />
            </SwiperSlide >
          )
        })
      )
    } else {
      return (
        <p className="c__article_notfound c__common_text">
          データの取得に失敗しました。<br />
          時間をおいて再度お試しください。
        </p>
      )
    }
  }, [articles])


  useEffect(() => {
    handleSlide();
  }, [articles])

  function handleSlide() {
    const swiper = qs('.about_creator_swiper')?.swiper;
    swiper?.on('slideChange', function (e: any) {

      // スライドされたらボタンのアクティブを切り替える
      removeClassAll(qsAll(".creator_button"), "active");
      const index = swiper.realIndex;
      addClass(qs(`.creator_button[data-creator-index="${index}"]`), "active");

      // 省略された文章が展開されていたら閉じる
      addClassAll(qsAll(".js__will_elipsis"), "elipsis_active");
      removeClassAll(qsAll(".c__navigation_button.spread"), "spread");
    });
  }


  return (
    <>
      <div id="js__creator_section" className="about_creator_list_wrapper c__js_fade">
        <PrimaryHeading letter="creator" />
        <div className="about_creator_list">
          {showCreatorsList}
        </div>
      </div>


      <div className="about_creator_slider_wrapper c__js_fade" key={routeUniqueKey}>

        <Swiper
          centeredSlides={true}
          // effect={"fade"}
          modules={[Navigation]}
          speed={700}
          loop={true}
          slidesPerView={1}
          initialSlide={1}// これを設定しないと先頭の解釈がずれる
          draggable={true}
          navigation={{
            prevEl: "#about_creator_swiper_prev .c__navigation_button",
            nextEl: "#about_creator_swiper_next .c__navigation_button"
          }}
          className="about_creator_swiper"
          spaceBetween={20}
        >
          {showCreatorsSlider}
        </Swiper>

        <div id="about_creator_swiper_prev">
          <NavigationButton variant="left" />
        </div>
        <div id="about_creator_swiper_next">
          <NavigationButton variant="" />
        </div>


      </div>

    </>
  );
}