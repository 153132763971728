import { d } from 'modules/cipher'
export const SITE_TITLE = "合同会社NIN - CREATIVE DIRECTION";
export const SITE_URL_BASE = "https://ninworks.com";
export const SITE_DESCRIPTION_BASE = "「 自分らしく、あなたらしく 」という考えの下、映像を中心とした創作を行う制作会社です。ものづくりが持つ「 人と人を結ぶチカラ 」を信じ、思いやりを込めた創作活動を心掛けています。";
export const API_BASE_URL = d(process.env.REACT_APP_BACKEND_API_DOMAIN) + "/wp-json/wp/v2";

function isProduction() {
  return process.env.REACT_APP_CURRENT_ENV === 'production';
}

export const topicsCategoryIds = {
  "production": isProduction() ? 11 : 9,
  "supervision": isProduction() ? 12 : 10,
  "setup": isProduction() ? 13 : 11,
}
