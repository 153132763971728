import { useCallback, useEffect } from 'react'
import { useSetRecoilState, useRecoilValue } from "recoil"
import { FirstViewAtom } from "../atom/GlobalAtom"
import { FirstVisitedAtom } from "../atom/GlobalAtom"

// 背景のstateを管理
type Background = State & {
  scrolled: () => void;
  start: () => void;
  visited: () => void;
};

type State = {
  isScrolled: boolean;
  isFirstVisit: boolean;
};

const useFirstView = (): Background => {

  const isScrolled = useRecoilValue(FirstViewAtom); // スクロールされたかどうか
  const setIsScrolled = useSetRecoilState(FirstViewAtom); // スクロールされたかどうかをセット
  const start = useCallback(() => setIsScrolled(false), []); // スクロール状態を false に設定
  
  const isFirstVisit = useRecoilValue(FirstVisitedAtom); // 初回訪問かどうか
  const setIsFirstVisit = useSetRecoilState(FirstVisitedAtom); // 初回訪問かどうかをセット
  
  // 呼び出し元でこれを呼び出せば初回訪問状態を false に設定できる、つまりローディングアニメーションをスキップできる
  const visited = useCallback(() => setIsFirstVisit(false), []); // 初回訪問状態を false に設定
  const scrolled = useCallback(() => setIsScrolled(true), []); // スクロール状態を true に設定


  return {
    isScrolled,
    scrolled,
    start,
    isFirstVisit,
    visited,
  };
}
export default useFirstView;